import React, { useState, useContext, useEffect } from "react";
import { Progress } from "rsuite";
import DatePicker from "react-date-picker";
import "rsuite/dist/styles/rsuite-default.css";
import { authFetch } from "./auth";
import { CompanyContext } from "./companyContext";

function ProgressBar(props) {
  const { Line } = Progress;
  const [startDate, setStartDate] = useState(new Date());
  const [colour, setColour] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [company] = useContext(CompanyContext);

  // Colour codes for progress bar
  const GREEN = "#00A550";
  const ORANGE = "#FFA500";
  const RED = "#ED1b24";

  const submitStartDate = (date) => {
    setStartDate(date);
    authFetch(`/api/company/${company}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start_date: date.toLocaleDateString("en-GB"),
      }),
    });
  };

  const submitEndDate = (date) => {
    setEndDate(date);
    authFetch(`/api/company/${company}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        end_date: date.toLocaleDateString("en-GB"),
      }),
    });
  };

  const submitColour = () => {
    let newColour =
      colour === "#9f9f9f" || colour === RED // If colour is grey or red
        ? GREEN // Set colour to green
        : colour === GREEN // Else if colour is green
        ? ORANGE // Set colour to orange
        : colour === ORANGE // Else if colour is orange
        ? RED // Set colour to red
        : GREEN;
    setColour(newColour);
    authFetch(`/api/company/${company}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        progress_colour: newColour,
      }),
    });
  };

  useEffect(
    (_) => {
      authFetch(`/api/company/${company}`)
        .then((response) => response.json())
        .then((response) => {
          setStartDate(
            response.start_date != null
              ? new Date(response.start_date)
              : undefined
          );
          setEndDate(
            response.end_date != null ? new Date(response.end_date) : undefined
          );
          setColour(response.progress_colour);
        });
    },
    [company]
  );

  return (
    <div className="mt-2">
      <div className="d-flex justify-content-center">
        <DatePicker
          onChange={(date) => submitEndDate(date)}
          value={endDate}
          format="d/M/y"
          clearIcon={null}
        />
      </div>
      <Line
        vertical
        percent={props.progress}
        style={{ height: "20vh" }}
        onClick={submitColour}
        strokeColor={colour}
      />
      <div className="d-flex justify-content-center">
        <DatePicker
          onChange={(date) => submitStartDate(date)}
          value={startDate}
          format="d/M/y"
          clearIcon={null}
        />
      </div>
    </div>
  );
}

export default ProgressBar;
