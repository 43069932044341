import React, { useContext, useState } from "react";
import { MonthContext } from "./monthContext";
import { CompanyContext } from "./companyContext";
import { Card } from "react-bootstrap";
import { authFetch } from "./auth";

function MonthComp(props) {
  const [company] = useContext(CompanyContext);
  const [month, setMonth] = useContext(MonthContext);
  const [colour, setColour] = useState("");

  // Colour codes for card
  const GREEN = "success";
  const RED = "danger";
  const GREY = "grey";

  const handleClick = (_) => {
    setMonth(props.month_num);

    if (month === props.month_num) {
      let newColour =
        colour === GREY
          ? GREEN
          : colour === GREEN
          ? RED
          : colour === RED
          ? GREY
          : GREEN;
      setColour(newColour);

      authFetch(`/api/companymonth/${company}/${props.month_num}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          colour: newColour,
        }),
      });
    }
  };
  return (
    <Card
      tag="a"
      bg={
        colour !== ""
          ? colour
          : props.colour === "grey"
          ? undefined
          : props.colour
      }
      text={colour === GREEN || colour === RED ? "light" : "dark"}
      style={{
        width: 100,
        height: 50,
        textAlign: "center",
        cursor: "pointer",
        borderWidth: 5,
      }}
      border={props.month_num === month ? "dark" : "secondary"}
      className="m-1 rounded d-flex align-items-center justify-content-center"
      onClick={(_) => handleClick()}
    >
      <Card.Body className="p-0 d-flex align-items-center justify-content-center">
        <Card.Title className="p-0 m-0 h2">{props.month_name}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default MonthComp;
