import React from "react";
import { Navbar, Image, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import logo from "../img/LeanDashboard.png";
import text from "../img/homepage.jpg";

function IndexView(props) {
  const logoImgStyle = {
    maxHeight: 50,
    width: "auto",
  };

  return (
    <div>
      <Navbar collapseOnSelect bg="light" expand="sm" variant="light">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Navbar.Collapse className="justify-content-start">
            <Nav.Item>
              <Nav.Link style={{ fontSize: "1.1rem" }} href="/login">
                Login
              </Nav.Link>
            </Nav.Item>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <a
              href="https://www.simplicityworks.com.au"
              className="d-none d-md-block"
            >
              <Image style={logoImgStyle} src={logo} />
            </a>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Navbar>
			<Image src={text} width="100%" />
    </div>
  );
}

export default withRouter(IndexView);
