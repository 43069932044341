import React, { useState, useEffect } from 'react';
import { Jumbotron, Button, CardColumns } from 'react-bootstrap';
import { withRouter, useRouteMatch, Link } from 'react-router-dom';
import { authFetch } from './auth';
import NavigationBar from './navigationBar';
import OverviewCard from './overviewCard';
import Improvements from './improvements';
import Projects from './projects';

function ModuleView(props) {
    const [module, setModule] = useState({});
    const match = useRouteMatch();

    useEffect(_ => {
        authFetch(`/api/getModule/${ match.params.code }`)
        .then(response => response.json())
        .then(response => setModule(response));
    }, [match.params.code]);

    return(
        <div>
            <NavigationBar title={ module.title } />
            <center>
                <h2 className="mt-5">{ module.title }</h2>
            </center>
            <Jumbotron className="m-5">
                <h3 className="text-center mb-3">Why?</h3>
                { module.details
                    ? module.details.map((data, idx) => (
                        <p 
                            key={ idx }
                            className="text-center h6"
                            style={{ fontSize: "1.3rem" }}
                        >
                            { data }
                        </p>
                    ))
                    : null }
            </Jumbotron>
            <Jumbotron className="ml-5 mr-5 mt-5 mb-2">
                <h3 className="text-center mb-3">Outcomes</h3>
                <CardColumns>
                { module.outcomes 
                    ? module.outcomes.map((data, idx) => (
                        <OverviewCard
                            key={ idx }
                            module={ match.params.code }
                            outcome={ data.id }
                            data={ data.content }
                        />
                )) 
                : null }
                </CardColumns>
            </Jumbotron>
            <div className="d-flex justify-content-center mb-0">
                <Link to={ '/dashboard' }>
                    <Button variant="outline-primary" className="ml-1 mr-1">Back</Button>
                </Link>
            </div>
            {
                match.params.code === "ideas" ? <Improvements module_code={ match.params.code } /> : null
            }
            {
                match.params.code === "5S" ? <Projects module_code={ match.params.code } />: null
            }
            
        </div>
    );
}

export default withRouter(ModuleView);