import React, { createContext, useState } from 'react';

var date = new Date();
var currentMonth = date.getMonth() + 1;

const MonthContext = createContext(currentMonth);
const MonthContextProvider = (props) => {
    const [month, setMonth] = useState(currentMonth);
    return (
        <MonthContext.Provider value={[month, setMonth]}>
            { props.children }
        </MonthContext.Provider>
    );
}

export { MonthContext, MonthContextProvider };