import React, { useEffect, useContext, useState } from "react";
import { Navbar, Image } from "react-bootstrap";
import logo from "../img/LeanDashboard.png";
import { Form } from "react-bootstrap";
import { CompanyContext } from "./companyContext";
import { authFetch } from "./auth";

function NavigationBar(props) {
  const [sponsor, setSponsor] = useState("");
  const [leader, setLeader] = useState("");
  const [company] = useContext(CompanyContext);

  useEffect((_) => {
    authFetch(`/api/company/${company}`)
      .then((response) => response.json())
      .then((response) => {
        setSponsor(response.sponsor ? response.sponsor : "");
        setLeader(response.leader ? response.leader : "");
      })
      .catch((error) => console.log(`Error: ${error}`));
  });


  const logoImgStyle = {
    maxHeight: 50,
    width: "auto",
  };

  const submitSponsor = (e) => {
    setSponsor(e.target.value);
    authFetch(`/api/company/${company}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sponsor: e.target.value,
      }),
    });
  };

  const submitLeader = (e) => {
    setLeader(e.target.value);
    authFetch(`/api/company/${company}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        leader: e.target.value,
      }),
    });
  };

  return (
    <div>
      <Navbar collapseOnSelect bg="light" expand="sm" variant="light">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Navbar.Collapse className="justify-content-start">
            <div className="d-none d-md-block">
              <label>
                <strong>Program Sponsor</strong>
              </label>
              <Form.Control
                placeholder="Program Sponsor"
                onBlur={submitSponsor}
                style={{ width: "175px" }}
                defaultValue={sponsor}
              />
              <label htmlFor="leader">
                <strong>Lean Leader</strong>
              </label>
              <Form.Control
                placeholder="Lean Leader"
                onBlur={submitLeader}
                style={{ width: "175px" }}
                defaultValue={leader}
              />
            </div>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <a
              href="https://www.simplicityworks.com.au"
              className="d-none d-md-block"
            >
              <Image style={logoImgStyle} src={logo} />
            </a>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
