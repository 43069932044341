import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import HomeView from './components/HomeView';
import ModuleView from './components/ModuleView';
import CompanyView from './components/CompanyView';
import LoginView from './components/LoginScreen';
import IndexView from './components/IndexView';
import { MonthContextProvider } from './components/monthContext';
import { CompanyContextProvider } from './components/companyContext';
import { useAuth } from './components/auth';
import NoteView from './components/NoteView';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();

  return <Route {...rest} render={(props) => (
    logged
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
}

function App() {

  return (
    <CompanyContextProvider>
      <MonthContextProvider>
        <Switch>
          <Route path="/" component={ IndexView } exact />
          <PrivateRoute path="/dashboard" component={ HomeView } exact />
          <PrivateRoute path="/module/:code" component={ ModuleView } exact />
          <PrivateRoute path="/companies" component={ CompanyView } exact />
          <Route path="/login" component={ LoginView } exact />
          <PrivateRoute path="/notes" component={ NoteView } exact />
        </Switch>
      </MonthContextProvider>
    </CompanyContextProvider>
  );
}

export default App;
