import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Module(props) {
  const moduleValue = props.value ? props.value : "0";

  const style = {
    minWidth: 150,
  };

  return (
    <div className="justify-content-center" style={style}>
      <Card className="justify-content-center border-dark m-2 mb-1">
        <Card.Title style={{ textAlign: "center" }} className="p-1">
          {props.title}
        </Card.Title>
        <hr />
        <Card.Body style={{ textAlign: "center" }} className="pt-0">
          <Link to={`/module/${props.code}`}>
            {props.answers ? (
              <Button variant={getColour(props.answers)}>{moduleValue}</Button>
            ) : (
              <Button variant="secondary">{moduleValue}</Button>
            )}
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

function getColour(answers) {
  let total = 3;
  // Make sure answers array always has 3 entries
  while (answers.length < 3) {
    answers.push(null);
  }

  // Calculate total
  answers.forEach((answer) => {
    if (answer === "no") {
      total--;
    } else if (answer === null) {
      total -= 2;
    }
  });

  // Set button colour based on total
  if (total === 3) {
    return "success";
  }
  if (total >= 1 && total < 3) {
    return "warning";
  }
  if (total >= -2 && total < 1) {
    return "danger";
  }

  return "secondary";
}

export default Module;
