import React, { createContext, useState } from 'react';

const CompanyContext = createContext(1);
const CompanyContextProvider = (props) => {
    const [company, setCompany] = useState(1);
    return (
        <CompanyContext.Provider value={[company, setCompany]}>
            { props.children }
        </CompanyContext.Provider>
    );
}

export { CompanyContext, CompanyContextProvider };