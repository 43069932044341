import React, { useState, useContext } from 'react';
import { InputGroup, Button, Form } from 'react-bootstrap';
import { MonthContext } from './monthContext';
import { CompanyContext } from './companyContext';
import { authFetch } from './auth';

function Project(props) {
    const [description, setDescription] = useState('');
    const [month] = useContext(MonthContext);
    const [company] = useContext(CompanyContext);
    
    const validInput = _ => {
        return (props.description.length > 0) || (description.length === 0);
    }

    const newProject = _ => {
        authFetch(`/api/project/${ month }/${ company }/${ props.module_code }`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                desc: description,
            })
        });
    };

    const quarterNum = _ => {
        var j = props.number % 10,
            k = props.number % 100;
        if (j === 1 && k !== 11) {
            return props.number + "st";
        }
        if (j === 2 && k !== 12) {
            return props.number + "nd";
        }
        if (j === 3 && k !== 13) {
            return props.number + "rd";
        }
        return props.number + "th";
    };

    return (
        <Form className="justify-content-center border rounded m-2 mb-1 col-xs-12 col-md-8 col-xl-7">
            <h4 className="mt-2" style={{ textAlign: "center" }}>
                { quarterNum() } Quarter
            </h4>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>Project</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    value={ props.description.length > 0 ? props.description : description }
                    readOnly={ props.description.length > 0 }
                    onChange={ e => setDescription(e.target.value) }
                />
            </InputGroup>
            <Button 
                variant="secondary"
                className="mb-3"
                style={{ maxWidth: 80 }}
                disabled={ validInput() }
                onClick={ _ => newProject() }
            >
                Save
            </Button>
        </Form>
    );
}

export default Project;