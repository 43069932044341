import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "./auth";

function LoginView(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginError = (msg) => toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
  });
  let history = useHistory();

  const validInput = (_) => {
    return username.length > 0 && password.length > 0;
  };

  const submit = (e) => {
    e.preventDefault();
    fetch(`/authenticate`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((r) => r.json())
      .then((token) => {
        if (token.access_token) {
          login(token);
          history.push("/dashboard");
        } else {
          loginError(token.message);
          console.log(token.message);
        }
      });
  };

  return (
    <div className="d-flex align-items-center flex-column mt-5 mb-5">
      <h1>Login</h1>
      <Form onSubmit={submit}>
        <Form.Group size="lg" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={username}
            autoComplete="username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validInput()}>
          Login
        </Button>
      </Form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
}

export default withRouter(LoginView);
