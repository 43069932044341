import React, { useState, useContext, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Button,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { MonthContext } from "./monthContext";
import { CompanyContext } from "./companyContext";
import { authFetch } from "./auth";

function Action(props) {
  const [action, setAction] = useState("");
  const [who, setWho] = useState("");
  const [due, setDue] = useState(props.due);
  const [month] = useContext(MonthContext);
  const [company] = useContext(CompanyContext);
  const months = [];
  // Add 12 months to list of months
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }

  useEffect(
    (_) => {
      setAction(props.description);
      setWho(props.who);
      setDue(props.due);
    },
    [props.description, props.who, props.due]
  );

  const submit = (_) => {
    authFetch(`/api/action/${month}/${company}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: action,
        who: who,
        due: due,
      }),
    });
  };

  return (
    <Form className="justify-content-center border-dark border rounded m-2 mb-1 col-xs-12 col-md-8 col-xl-7">
      <h4 className="mt-2" style={{ textAlign: "center" }}>
        Action {props.number}
      </h4>
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>Action</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          className="form-control-lg"
          placeholder={props.description !== "" ? props.description : ""}
          onChange={(e) => setAction(e.target.value)}
          readOnly={props.description}
          value={action}
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>Who</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          className="form-control-lg"
          onChange={(e) => setWho(e.target.value)}
          readOnly={props.who}
          value={who}
        />
        <InputGroup.Prepend>
          <InputGroup.Text>Action Duration</InputGroup.Text>
        </InputGroup.Prepend>
        <DropdownButton
          className="text-lg"
          as={InputGroup.append}
          variant="outline-secondary"
          title="Duration"
          id="input-group-dropdown-1"
          onSelect={(e) => setDue(parseInt(e))}
        >
          {months.map((value) => (
            <Dropdown.Item key={value} eventKey={value}>
              {value} month(s)
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <InputGroup.Prepend>
          <InputGroup.Text>{due}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <Button
        variant="secondary"
        className="mb-3"
        style={{ maxWidth: 80 }}
        onClick={(_) => submit()}
      >
        Save
      </Button>
    </Form>
  );
}

export default Action;
