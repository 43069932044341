import React from 'react';
import { Image } from 'react-bootstrap';
import Module from './module';
import triangle from '../img/triangle.png';
import reverseTriangle from '../img/triangle-reverse.png';

function Modules(props) {
    const triangleStyle = {
        maxHeight: 240
    }

    return (
        <div>
            <div className="d-flex justify-content-center">
                <div className="card-deck mb-1 mt-1 justify-content-center col-xs-3 col-sm-4 col-xl-8">
                    { props.modules.map((module, idx) => (
                            <Module
                            key={ idx }
                            title={ module.title } 
                            value={ module.value } 
                            code={ module.code }
                            answers={ module.answers }
                    />
                    )) }
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Image
                    className="img-fluid col-xs-12 col-md-8 col-xl-7"
                    style={ triangleStyle }
                    src={ reverseTriangle }
                />
            </div>
        </div>
    );
}

export default Modules;