import React, { useEffect, useState, useContext } from 'react';
import { MonthContext } from './monthContext';
import { CompanyContext } from './companyContext';
import { authFetch } from './auth';
import Action from './action';


function Actions(props) {
    const [actions, setActions] = useState([]);
    const [month] = useContext(MonthContext);
    const [company] = useContext(CompanyContext);

    useEffect(_ => {
        authFetch(`/api/action/${ month }/${ company }`)
        .then(response => response.json())
        .then(response => setActions(response))
    }, [month, company]);

    const populatedActions = _ => {
        let newActions = actions;
        while (actions.length < 3) {
            newActions.push({
                "due": 0,
                "description": "",
                "who": ""
            });
        }
        return newActions;
    }

    return (
        <div className="d-flex align-items-center flex-column mt-3 mb-5">
            <h2 className="mb-2">Actions</h2>
            {
                populatedActions().map((action, idx) => { return(
                    <Action 
                        key={ idx }
                        number={ idx + 1 }
                        description={ action['description'] }
                        who={ action['who'] }
                        start={ month }
                        due={ action['due'] }
                    />
                )})
            }
        </div>
    );
}

export default Actions;