import React, { useEffect, useState, useContext } from "react";
import { CardDeck } from "react-bootstrap";
import { CompanyContext } from './companyContext';
import MonthComp from "./month";
import { authFetch } from "./auth";

function Months(props) {
	const [colours, setColours] = useState("");
	const [company] = useContext(CompanyContext);

  useEffect(
    (_) => {
      authFetch(`/api/companymonth/${company}`)
        .then((response) => response.json())
        .then((response) => setColours(response))
        .catch((response) => console.log("hi"));
    },
    [company]
  );

  return (
    <div className="d-flex align-items-center flex-column mt-3 mb-5">
      <h2 className="mb-2">Months</h2>
      <CardDeck className="d-flex justify-content-center flex-wrap">
        {[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ].map((month_name, idx) => (
          <MonthComp key={idx} month_num={idx + 1} month_name={month_name} colour={colours[`${idx+1}`]} />
        ))}
      </CardDeck>
    </div>
  );
}

export default Months;
