import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import NavigationBar from "./navigationBar";
import Modules from "./modules";
import ProgressBar from "./progress";
import Actions from "./actions";
import Months from "./months";
import { MonthContext } from "./monthContext";
import { CompanyContext } from "./companyContext";
import { authFetch, logout } from "./auth";
import Pdf from "react-to-pdf";

function HomeView(props) {
  const [modules, setModules] = useState([]);
  const [progress, setProgress] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [month] = useContext(MonthContext);
  const [company] = useContext(CompanyContext);
  const pdfref = useRef();

  useEffect(
    (_) => {
      authFetch(`/api/allModules/${month}/${company}`)
        .then((response) => {
          if (response.status === 401) {
            return null;
          }
          return response.json();
        })
        .then((response) => {
          setModules(response);
        })
        .catch((error) => console.log(`Error: ${error}`));
    },
    [month, company]
  );

  useEffect(
    (_) => {
      var currentProgress = 0;
      modules.forEach((module) =>
        module.total === module.outcomes
          ? (currentProgress += module.value)
          : ""
      );
      setProgress(currentProgress);
    },
    [modules]
  );

  useEffect(
    (_) => {
      authFetch(`/api/company/${company}`)
        .then((response) => response.json())
        .then((response) => {
          setCompanyName(response.company ? response.company : "Select A Team");
        })
        .catch((error) => console.log(`Error: ${error}`));
    },
    [company]
  );

  return (
    <div>
      <NavigationBar title="Lean Dashboard" />
      <Button
        variant="outline-primary"
        className="m-2 pull-right"
        size="md"
        onClick={(_) => logout()}
      >
        Logout
      </Button>
      <Link to={"/companies"}>
        <Button variant="outline-secondary" className="m-2">
          Teams
        </Button>
      </Link>
      <Link to={"/notes"}>
        <Button variant="outline-secondary">Notes</Button>
      </Link>
      <Pdf targetRef={pdfref} filename="report.pdf" x={-50} y={30} scale={0.55}>
        {({ toPdf }) => (
          <Button variant="outline-secondary" className="m-2" onClick={toPdf}>
            Generate Pdf
          </Button>
        )}
      </Pdf>
      <div ref={pdfref}>
        <center className="mb-5">
          <h1 className="mb-1 display-2">
            <strong>{companyName}</strong>
          </h1>
          <p style={{ fontFamily: "kalam" }} className="h3">
            <span style={{ color: "#F49E44" }}>"</span>Lean does not create
            problems<span style={{ color: "#F49E44" }}>;</span> it exposes the
            problems your current system is hiding
            <span style={{ color: "#F49E44" }}>."</span>
          </p>
        </center>
        {modules.length > 0 ? <Modules modules={modules} /> : null}
        <ProgressBar progress={progress} />
        <Actions />
        <Months />
      </div>
    </div>
  );
}

export default withRouter(HomeView);
