import React, { useEffect, useState, useContext } from 'react';
import { MonthContext } from './monthContext';
import { CompanyContext } from './companyContext';
import { authFetch } from './auth';
import Improvement from './improvement';

function Improvements(props) {
    const [improvements, setImprovements] = useState([]);
    const [month] = useContext(MonthContext);
    const [company] = useContext(CompanyContext);

    useEffect(_ => {
        authFetch(`/api/improvement/${ month }/${ company }/${ props.module_code }`)
        .then(response => response.json())
        .then(response => setImprovements(response));
    }, [month, company, props.module_code]);

    return (
        <div className="d-flex align-items-center flex-column mt-3 mb-5">
            <h2 className="mb-2">Improvements</h2>
            {
                improvements.map((improvement, idx) => (
                    <Improvement
                        key={ idx }
                        description={ improvement.description }
                        module_code={ props.module_code }
                    />
                ))
            }
        </div>
    );
}

export default Improvements;