import React, { useState, useEffect, useContext } from "react";
import { Card, Button, Form, InputGroup, CardColumns } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import NavigationBar from "./navigationBar";
import { CompanyContext } from "./companyContext";
import { authFetch } from "./auth";

function CompanyView(props) {
  const [, setCompany] = useContext(CompanyContext);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [displayCard, setDisplayCard] = useState("none");

  useEffect((_) => {
    authFetch(`/api/company`)
      .then((response) => response.json())
      .then((response) => setCompanies(response))
      .catch((error) => console.log(`Error: ${error}`));
  }, []);

  const addCompany = (_) => {
    setDisplayCard("none");
    authFetch(`/api/company`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: companyName,
      }),
    })
      .then((response) => response.json())
      .then((response) => setCompanies(response));
  };

  const confirmDelete = (remove) => {
    if (window.confirm("Are you sure you want to delete this company?"))
      deleteCompany(remove);
  };

  const deleteCompany = (remove) => {
    authFetch(`/api/company`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: remove,
      }),
    })
      .then((response) => response.json())
      .then((response) => setCompanies(response));
  };

  return (
    <div>
      <NavigationBar title={module.title} />
      <Link to="/dashboard">
        <Button variant="outline-primary" className="m-2">
          Home
        </Button>
      </Link>
      <CardColumns
        className="d-flex justify-content-center"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        {companies.map((company, idx) => (
          <Card
            className="justify-content-center m-2 mb-1 w-75"
            key={idx}
          >
            <Card.Title
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
              className="p-1"
            >
              {company.name}
            </Card.Title>
            <Card.Body style={{ textAlign: "center" }} className="pt-0">
              <Button
                variant="outline-danger"
                style={{ fontsize: "1.2rem" }}
                onClick={(_) => {
                  confirmDelete(company.id);
                }}
              >
                Delete
              </Button>
              <Link to="/dashboard">
                <Button
                  variant="outline-primary"
                  onClick={(_) => {
                    setCompany(company.id);
                  }}
                  className="ml-2"
                >
                  View
                </Button>
              </Link>
            </Card.Body>
          </Card>
        ))}
        <Card
          className="justify-content-center m-2 mb-1 w-75"
          style={{ display: displayCard }}
        >
          <Card.Title
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "2rem",
            }}
            className="p-1"
          >
            New Team
          </Card.Title>
          <Card.Body style={{ textAlign: "left" }} className="pt-0">
            <InputGroup className="m-2 w-100 justify-content-center">
              <Form.Control
                className="m-2"
                placeholder="Company Name"
                defaultValue=""
                onBlur={(e) => setCompanyName(e.target.value)}
              />
            </InputGroup>
            <Button
              className="m-2 mb-3"
              style={{ maxWidth: 80 }}
              onClick={(_) => {
                addCompany();
              }}
            >
              Submit
            </Button>
          </Card.Body>
        </Card>
      </CardColumns>
      <div className="d-flex justify-content-center">
        <Button
          variant="outline-dark"
          size="lg"
          style={{ borderRadius: 100 }}
          onClick={(_) =>
            setDisplayCard(displayCard === "none" ? "block" : "none")
          }
        >
          New
        </Button>
      </div>
    </div>
  );
}

export default withRouter(CompanyView);
