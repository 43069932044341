import React, { useState, useEffect, useContext } from 'react';
import { Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { MonthContext } from './monthContext';
import { CompanyContext } from './companyContext';
import { authFetch } from './auth';

function OverviewCard(props) {
    const [radioValue, setRadioValue] = useState("");
    const [month] = useContext(MonthContext);
    const [company] = useContext(CompanyContext);

    useEffect(_ => {
        authFetch(`/api/answer/${ month }/${ company }/${ props.outcome }/${ props.module }`)
        .then(response => response.json())
        .then(response => setRadioValue(response.answer));
    }, [props.module, props.outcome, month, company]);

    const newAnswer = value => {
        authFetch(`/api/answer/${ month }/${ company }/${ props.outcome }/${ props.module }`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                value: value,
            })
        });
    };

    const radios = [
        { name: "Yes", value: "yes" },
        { name: "No", value: "no" }
    ];

    return (
        <Card className="justify-content-center m-2">
            <Card.Body style={{ textAlign: "center", minHeight: 100, fontSize: "1.2rem" }}>
                { props.data }
            </Card.Body>
            <Card.Body style={{ textAlign: "center", fontSize: "1.2rem" }} className="pt-0">
                <ButtonGroup toggle>
                { radios.map((radio, idx) => (
                    <ToggleButton
                        key={ idx }
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={ radio.value }
                        checked={ radioValue === radio.value }
                        onChange={ (e) => {
                            setRadioValue(e.currentTarget.value);
                            newAnswer(radio.value);
                        }}
                    >
                        {radio.name}
                    </ToggleButton>
                )) }
                </ButtonGroup>
            </Card.Body>
        </Card>
    )
}

export default OverviewCard;