import React, { useState, useContext, useEffect, useMemo } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { MonthContext } from './monthContext';
import { CompanyContext } from './companyContext';
import { authFetch } from './auth';
import { createEditor, Node } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const serialise = value => {
    return value.map(n => Node.string(n)).join('\n');
}

const deserialise = string => {
    return string.split('\n').map(line => {
        return {
            children: [{ text: line }],
        }
    });
}

function NoteView(props) {
    const editor = useMemo(_ => withReact(createEditor()), [])
    const [note, setNote] = useState([]);
    const [month] = useContext(MonthContext);
    const [company] = useContext(CompanyContext);

    useEffect(_ => {
        authFetch(`/api/note/${ month }/${ company }`)
        .then(response => response.json())
        .then(response => setNote(deserialise(response.note)));
    }, [month, company]);

    const saveNote = _ => {
        authFetch(`/api/note/${ month }/${ company }`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                note: serialise(note)
            })
        });
    }

    return(
        <div>
            <Button
                className="m-2"
                variant="outline-primary"
                onClick={ _ => { saveNote() }}
            >Save</Button>
            <Link to="/dashboard">
                <Button variant="outline-secondary">Dashboard</Button>
            </Link>
            <Slate 
                editor={ editor }
                value={ note }
                onChange={ text => setNote(text) }
                autoCorrect='false'
                autoCapitalize='false'
            >
                <Editable />
            </Slate>
        </div>
    );
}

export default withRouter(NoteView);